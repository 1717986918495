<template>
	<div>
		租赁
	</div>
</template>
<script>
	export default {
		components: {},
		data() {
			return {}
		},
		methods: {},
		mounted() {}
	};
</script>
<style lang='scss' scoped>
</style>
